import useTranslation from '@/utils/i18n/useTranslation';
import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

type DropdownMenuSelectProps = {
  label: string;
  isOpen?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  onClickClearSelection?: () => void;
};

const DropdownMenuSelect = (props: DropdownMenuSelectProps) => {
  const { label, isOpen = false, onClose, children, onClickClearSelection } = props;
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      size={{ base: 'full', md: 'lg' }}
      scrollBehavior='inside'
      onClose={() => onClose && onClose()}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {isOpen && (
          <ModalBody p={3} borderTop='1px' borderColor='neutral.300' bg='neutral.0'>
            <Flex>
              <Text mx='auto' fontWeight={700} my={3}>
                {label}
              </Text>
            </Flex>
            <Divider mt={2} />
            <Box
              bg='neutral.0'
              mt={1}
              py={2}
              rounded='md'
              border='1px'
              borderColor={{
                base: 'transparent',
                md: 'neutral.200',
              }}
            >
              {children}
            </Box>
            <Button width='full' onClick={onClickClearSelection}>
              <Text>{t('clear')}</Text>
            </Button>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

export default DropdownMenuSelect;
