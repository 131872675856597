import { OptionItem } from '@/common/types';
import useTranslation from '@/utils/i18n/useTranslation';
import {
  Box,
  BoxProps,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';
import { MdArrowDropDown, MdCancel } from 'react-icons/md';
import OptionCountLabel from './OptionCountLabel';

export type OrganizedFilterItemsType = {
  [key: string]: boolean;
};

export type CustomFieldOptionType = {
  label: string;
  customFieldId: number;
  selectOptions: OptionItem[];
};

type CommonOrganizedFilterProps = {
  organizedFiltersCount: number;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  resetOrganizedFilter: () => void;
  resetOrganizedFilterItemsOpenValue: () => void;
  children: ReactNode;
  boxProps?: BoxProps;
};

const CommonOrganizedFilterWrapper = (props: CommonOrganizedFilterProps) => {
  const {
    organizedFiltersCount,
    isOpen,
    setIsOpen,
    resetOrganizedFilter,
    resetOrganizedFilterItemsOpenValue,
    children,
    boxProps,
  } = props;

  const { t } = useTranslation();

  const isActive = useMemo(() => {
    return organizedFiltersCount > 0;
  }, [organizedFiltersCount]);

  return (
    <Box {...boxProps}>
      <Menu closeOnSelect={false} closeOnBlur={false} isOpen={isOpen}>
        <HStack
          _hover={{
            bg: isActive || isOpen ? undefined : 'neutral.50',
            borderColor: 'neutral.300',
            color: ' neutral.800',
          }}
          sx={
            isActive
              ? {
                  bg: 'primary.100',
                  borderColor: 'gray.300',
                }
              : undefined
          }
          zIndex={100}
          border='1px solid'
          borderColor='neutral.300'
          borderRadius='md'
          spacing='0'
          bgColor={isOpen ? 'gray.200' : 'transparent'}
        >
          <MenuButton
            size='sm'
            variant='outline'
            color={isActive ? 'primary.500' : 'neutral.800'}
            border={0}
            pr={0}
            as={Button}
            _hover={{ color: isActive ? 'primary.500' : 'neutral.800', bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            {t('others')}
          </MenuButton>
          <OptionCountLabel optionCount={organizedFiltersCount} />
          <IconButton
            aria-label='Reset Filter'
            size='sm'
            variant='outline'
            color={isActive ? 'primary.500' : 'neutral.500'}
            border={0}
            _hover={{ background: 'transparent' }}
            bg='transparent'
            _active={{}}
            onClick={isActive ? resetOrganizedFilter : () => setIsOpen(!isOpen)}
            icon={isActive ? <MdCancel /> : <MdArrowDropDown />}
            zIndex={100}
          />
        </HStack>
        <Box
          zIndex={isOpen ? 9 : -9}
          position='fixed'
          inset={0}
          onClick={() => {
            if (isOpen) {
              resetOrganizedFilterItemsOpenValue();
            }
            setIsOpen(!isOpen);
          }}
        />
        {isOpen && (
          <MenuList w='full' minW={150} zIndex={100}>
            {children}
          </MenuList>
        )}
      </Menu>
    </Box>
  );
};

export default CommonOrganizedFilterWrapper;
