import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SystemStyleObject,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import DropdownMenuOption from './DropdownMenuOption';
import DropdownMenuSelect from './DropdownMenuSelect';

type DropdownMenuProps = {
  children: ReactNode;
  containerSx?: SystemStyleObject;
  label: string;
  isOpen?: boolean;
  values?: string[] | number[];
  onToggle?: () => void;
  onClose?: () => void;
  onClickClearSelection?: () => void;
};

const DropdownMenu = (props: DropdownMenuProps) => {
  const {
    children,
    label,
    containerSx,
    isOpen,
    values = [],
    onToggle,
    onClose,
    onClickClearSelection,
  } = props;

  const { isDesktop, isMobile } = useScreenInfos();

  return (
    <>
      {isDesktop && (
        <Popover
          isOpen={isOpen}
          onClose={onClose}
          closeOnBlur={false}
          placement='right-start'
          isLazy
        >
          <PopoverTrigger>
            <Box w='full'>
              <DropdownMenuOption onClick={onToggle} label={label} values={values} />
            </Box>
          </PopoverTrigger>
          {isOpen && (
            <Portal>
              <PopoverContent sx={containerSx} zIndex={1200}>
                <PopoverBody>
                  <Box>{children}</Box>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          )}
        </Popover>
      )}
      {isMobile && (
        <>
          <DropdownMenuOption onClick={onToggle} label={label} values={values} />
          <DropdownMenuSelect
            isOpen={isOpen}
            onClose={onClose}
            label={label}
            onClickClearSelection={onClickClearSelection}
          >
            {children}
          </DropdownMenuSelect>
        </>
      )}
    </>
  );
};

export default DropdownMenu;
